import React from 'react';
const Bell = ({ width = '49', className = '', height = '45', viewBox='0 0 49 45' }) =>
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className ? className : "svg-icon"}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path id="svg-bell-b" d="M9.208 28.304a2.617 2.617 0 01-2.906-.058 2.624 2.624 0 01-1.03-2.899.998.998 0 10-1.92-.557 4.62 4.62 0 001.814 5.104 4.632 4.632 0 005.117.1 1.002 1.002 0 00-1.075-1.69zM36.526 4.4c0-2.426-1.974-4.4-4.4-4.4a4.406 4.406 0 00-4.4 4.4c0 .106.01.208.016.31-4.63-2.393-7.606-1.222-10.492 1.316l-.212.188c-8.05 7.104-10.726 7.828-11.51 7.796-3.52-.1-5.184 1.612-5.254 1.686a.995.995 0 00-.138 1.187c.134.23 3.38 5.722 13.683 12.842 10.695 7.392 16.637 8.233 16.883 8.265a1 1 0 001.027-.554c.045-.089 1.063-2.258-.281-5.513-.298-.72-.58-3.475 3.222-13.523l.1-.262c1.283-3.408 1.408-6.314-1.616-9.463A4.408 4.408 0 0036.526 4.4zm-4.4-2.4c1.325 0 2.4 1.078 2.4 2.4 0 1.322-1.078 2.4-2.4 2.4a2.403 2.403 0 01-2.4-2.4c0-1.322 1.076-2.4 2.4-2.4zm.775 15.43l-.1.26c-3.023 7.99-4.07 12.896-3.2 14.995.577 1.395.596 2.473.51 3.136-1.95-.5-7.185-2.234-15.16-7.741-7.724-5.338-11.35-9.75-12.581-11.45.592-.313 1.603-.668 3.094-.62 2.26.067 6.49-2.644 12.896-8.295l.208-.185c2.413-2.122 4.86-3.565 10.403.265 5.555 3.837 5.1 6.522 3.93 9.635zm-5.303 10.308c-10.966-4.73-16.496-11.11-17.09-11.824a1.003 1.003 0 00-1.409-.128c-.422.355-.48.985-.128 1.408.627.748 6.445 7.465 17.834 12.377a1.001 1.001 0 001.315-.522.994.994 0 00-.522-1.311z"/>
      <filter id="svg-bell-a" width="154.8%" height="152.6%" x="-27.4%" y="-26.3%" filterUnits="objectBoundingBox">
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3"/>
        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.839215686 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feMorphology in="SourceAlpha" operator="dilate" radius=".5" result="shadowSpreadOuter2"/>
        <feOffset in="shadowSpreadOuter2" result="shadowOffsetOuter2"/>
        <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="2"/>
        <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0.839215686 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1"/>
          <feMergeNode in="shadowMatrixOuter2"/>
        </feMerge>
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(6 6)">
      <use fill="#000" filter="url(#svg-bell-a)" href="#svg-bell-b"/>
      <use fill="#FFCDCD" href="#svg-bell-b"/>
    </g>
  </svg>;

  export default Bell;